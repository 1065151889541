import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../util/types";

export const starIconStyle = ({
  maxStars,
  globalTheme,
}: {
  maxStars: number;
  globalTheme: ThemeObjectInterFace;
}): SxProps => ({
  fontSize: maxStars >= 10 ? "2rem" : { md: "4rem", xs: "2.5rem" },
  color: globalTheme?.main_color,
});

export const starRatingContainer: SxProps = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

/**
 * *star container
 */
export const starRatingInnerContainer = (isLandScapeMode: boolean) => ({
  display: "flex",
  justifyContent: isLandScapeMode ? "space-around" : "space-between",
  width: "100%",
});

export const starIconeWraper: SxProps = {
  cursor: "pointer",
  transition: "all 0.2s ease-in-out",
  paddingY: { md: 1 },
  marginY: { md: 1 },
};
