import { SxProps } from "@mui/material";
import { ThemeObjectInterFace } from "../../../../../../util/types";

export const LeftSideContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: { md: "20%", xs: "30%" },

  height: "100%",
};

export const LeftSideChildStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: 3,
};
export const RightSideContainer: SxProps = {
  width: { md: "80%", xs: "70%" },
  display: "flex",
  flexDirection: "column",
};
/* c8 ignore start */
export const TextContainer = (
  globalTheme: ThemeObjectInterFace,
  height: number
): SxProps => ({
  color: globalTheme.main_color,
  fontSize: { sm: "1.2rem", xs: "1rem" },
  minWidth: "150px",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  minHeight: height || "auto",
});
/* c8 ignore end */
export const rowratingPointText = (
  globalTheme: ThemeObjectInterFace,
  isImageExist: boolean
): SxProps => {
  return {
    color: globalTheme?.main_color,
    fontWeight: "300",

    textAlign: /* c8 ignore next */ isImageExist ? "center" : "end",
    width: "100%",
    fontSize: { md: "20px", xs: "12px" },
    wordBreak: "break-word",
  };
};
export const textContainer = (
  isImageExist: boolean,
  height: number
): SxProps => ({
  height: height > 0 ? height : "inherit",
  minHeight: { md: 95, xs: 40 },
  display: "flex",
  alignItems: "center",
  justifyContent: isImageExist ? "center" : "space-between",
  width: "100%",
});
export const CommonImageStyle = (height: number): SxProps => ({
  maxHeight: height,
  minHeight: { md: 50, xs: 40 },
  paddingTop: { xs: 0.5 },
});
export const columnContainer = (
  globalTheme: ThemeObjectInterFace
): SxProps => ({
  display: "flex",
  justifyContent: "space-between",
  color: globalTheme.main_color,
});

export const starComponentContainer = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

export const columnInnerContainer = {
  width: "100%",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
};
