import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../../util/types";

export const loadingContainer: SxProps = {
  padding: "2rem 0",
  display: "flex",
  justifyContent: "center",
};

export const valueContainer: SxProps = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

/* c8 ignore next */
export const valueContent = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean
): SxProps => ({
  border: 1,
  width: "100%",
  textAlign: "center",
  borderColor: globalTheme?.main_color,
  backgroundColor: isSelected ? globalTheme?.main_color : "white",
  color: isSelected ? "white" : globalTheme?.main_color,
  cursor: "pointer",
  fontSize: 20,
});
