/**
 * * style for rating grid parent component
 */

import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../util/types";

export const gridMainContainer: SxProps = {
  padding: { md: "20px 25px", sm: "10px 15px" },
  margin: "0 auto",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: { sm: "30px" },
};

export const gridMainBox = (
  globalTheme: ThemeObjectInterFace,
  isGrid_numeric: boolean,
  isColumns: string[] | undefined
): SxProps => ({
  width:
    /* c8 ignore next 1 */
    isGrid_numeric && !isColumns ? "auto" : "100%",
  ...RatingGridParentStyle(globalTheme),
});

export const RatingGridParentStyle = (
  globalTheme: ThemeObjectInterFace
): SxProps => ({
  padding: "20px 25px",
  border: `2px solid ${globalTheme?.main_color}`,
  margin: "0 auto 20px",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 5.95px 5.95px 2.6px",
});

export const actionBtnWrapper: SxProps = {
  position: "absolute",
  bottom: 0,
  right: 0,
};
