/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
// GlobalContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { GlobalContextProps, GlobalObject } from "../util/types";
import { ThemeObjectLandscape } from "../util/constants";

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [globalObject, setGlobalObject] = useState<GlobalObject>({
    themeData: { theme: { ...ThemeObjectLandscape, orientation: "" } },
  });

  /* c8 ignore next 3 */
  const updateGlobalObject = (newObject: GlobalObject) => {
    setGlobalObject(newObject);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URL(window?.location?.href);

        const pathname = url.pathname;
        const id = pathname.split("/")[1];
        /* c8 ignore next 9 */
        const api_protocol =
          import.meta.env.VITE_MX8_SURVEY_API_PROTOCOL ?? "https";
        const api_url = `${api_protocol}://${
          import.meta.env.VITE_MX8_SURVEY_API_DOMAIN
        }/v1/theme/${id}`;
        const response = await fetch(api_url, {
          method: "GET",
        });
        /* c8 ignore next */
        const themeData = await response.json();
        /* c8 ignore next 8 */
        if (response.ok) {
          localStorage.clear();
          updateGlobalObject({
            ...globalObject,
            themeData,
          });
        }
        /* c8 ignore next 3*/
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const contextValue = useMemo(
    () => ({ globalObject, updateGlobalObject }),
    [globalObject, updateGlobalObject]
  );
  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };

export const useThemeContext = () => {
  const context = useContext(GlobalContext);
  /* c8 ignore next 3 */
  if (!context) {
    throw new Error("useThemeContext must be used inside the ThemeProvider");
  }
  return context;
};
