import { FC, useMemo } from "react";
import { Box } from "@mui/system";
import { CustomActionRequest, TextActionRequest } from "chat-ui-react";
import {
  MultiSelectInput,
  RankSorter,
  Rating,
  SelectInput,
  TextInput,
  ImageQuestion,
  MaxDiff,
  ThisOrThat,
  MultiTextInputs,
  GridSelectParent,
  EmbedContent,
  RatingGridParent,
} from "../../InputComponents";
import {
  CompleteInputResponse,
  CustomComponentType,
  MultiSelectTypes,
  RankSorterTypes,
  RatingResponse,
  SelectTypes,
  ChatQuestionProps,
  ImageQuestionType,
  TimerImageTypes,
  MaxDiffActionRequestTypes,
  GridSelectAction,
  ThisOrThatActionRequestTypes,
  EmbedContentSendButtonActionReq,
} from "../../../util/types";
import { CircularProgress } from "@mui/material";
import { Modal } from "../../ui";
import { useThemeContext } from "../../../globalContext/global-context";

const ChatQuestion: FC<ChatQuestionProps> = (props) => {
  const {
    actReq,
    chatCtl,
    setRankMostLeast,
    setSliderValue,
    setUpdateMarkValue,
    sliderValue,
    updateMarkValue,
    isLandScapeMode,
    handleImageClick,
    surveyQuestion,
    loading,
    isCommentModalOpen,
    setIsOtherModalOpen,
    isOtherModalOpen,
    setOtherOptionString,
    otherOptionString,
    setActionReqForSelectMulti,
    actionReqForSelectMulti,
  } = props;
  const CustomComponent = useMemo((): CustomComponentType => {
    if (!actReq || actReq.type !== "custom") {
      return null as unknown as CustomComponentType;
    }
    return (actReq as CustomActionRequest)
      .Component as unknown as CustomComponentType;
  }, [actReq]);
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  const inputProps = {
    isLandScapeMode,
    chatController: chatCtl,
    handleImageClick,
    surveyQuestion,
    isCommentModalOpen,
    setIsOtherModalOpen,
    isOtherModalOpen,
    setOtherOptionString,
    otherOptionString,
    setActionReqForSelectMulti,
    actionReqForSelectMulti,
  };

  if (loading && isLandScapeMode) {
    return (
      <CircularProgress
        data-testid="response-circular-loader"
        style={{
          height: "60px",
          width: "60px",
          color: globalTheme?.main_color,
        }}
      />
    );
  }
  const renderQuestionComponent = () => {
    switch (actReq?.type) {
      case "text":
      case "numeric":
      case "phone_number":
        return (
          <TextInput
            {...inputProps}
            actionRequest={actReq as TextActionRequest}
          />
        );
      case "text_list":
        return (
          <MultiTextInputs
            {...inputProps}
            actionRequest={actReq as TextActionRequest}
          />
        );
      case "select":
        return (
          <SelectInput {...inputProps} actionRequest={actReq as SelectTypes} />
        );
      case "multi-select":
        return (
          <MultiSelectInput
            {...inputProps}
            actionRequest={actReq as MultiSelectTypes}
          />
        );
      case "rank_sort":
        return (
          <RankSorter
            {...inputProps}
            actionRequest={actReq as RankSorterTypes}
            setRankMostLeast={setRankMostLeast}
          />
        );
      case "rating":
        return (
          <Rating
            {...inputProps}
            key={"rating-1"}
            actionRequest={actReq as RatingResponse}
            setSliderValue={setSliderValue}
            setUpdateMarkValue={setUpdateMarkValue}
            sliderValue={sliderValue}
            updateMarkValue={updateMarkValue}
          />
        );
      case "image":
        return (
          <ImageQuestion
            {...inputProps}
            surveyQuestion={surveyQuestion as ImageQuestionType}
            actionRequest={actReq as TimerImageTypes}
          />
        );
      case "grid_select":
      case "grid_multi_select":
        return (
          <GridSelectParent
            actionRequest={actReq as GridSelectAction}
            setUpdateMarkValue={setUpdateMarkValue}
            updateMarkValue={updateMarkValue}
            {...inputProps}
          />
        );
      case "grid_rating":
      case "grid_numeric":
        return (
          <RatingGridParent
            {...inputProps}
            actionRequest={actReq as GridSelectAction}
            setUpdateMarkValue={setUpdateMarkValue}
            updateMarkValue={updateMarkValue}
          />
        );

      case "max_diff":
        return (
          <MaxDiff
            {...inputProps}
            actionRequest={actReq as MaxDiffActionRequestTypes}
          />
        );
      case "grid_this_or_that":
      case "grid_this_or_that_rating":
        return (
          <ThisOrThat
            {...inputProps}
            actionRequest={actReq as ThisOrThatActionRequestTypes}
          />
        );
      case "embed":
        if (isLandScapeMode) {
          return (
            <EmbedContent
              key={"embed-content"}
              isLandScapeMode={isLandScapeMode}
              actionRequest={actReq as EmbedContentSendButtonActionReq}
            />
          );
        }
        break;
      case "custom":
        return (
          <CustomComponent
            chatController={chatCtl}
            actionRequest={actReq as CustomActionRequest}
          />
        );
      case "complete":
        return (
          <Modal
            isLandScapeMode={isLandScapeMode}
            actionRequest={actReq as CompleteInputResponse}
            type="CompleteInput"
          />
        );
    }
  };

  return (
    <Box data-testid="chat-question" width={"100%"}>
      {/* c8 ignore next 3 */}
      {renderQuestionComponent()}
    </Box>
  );
};

export default ChatQuestion;
