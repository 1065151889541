import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { SendUndoActions, SendUndoProps } from "../../../util/types";
import { UNDO_MSG_CONTENT } from "../../../util/constants";
import { container } from "./styles";
import ActionButton from "../ActionButton";

const SendUndoButton: React.FC<SendUndoProps> = ({
  onClick,
  chatController,
  actionRequest,
  hideSendBtn,
  stopBack,
  disableSendBtn,
  isCommentModalOpen,
  isOtherModalOpen,
}) => {
  const undoFunction = React.useCallback(() => {
    const res = { type: "undo", value: UNDO_MSG_CONTENT };
    chatController?.setActionResponse(actionRequest, res);
  }, [chatController, actionRequest]);
  const checkIsSpecify = React.useCallback(() => {
    const actReq = actionRequest as SendUndoActions;
    const check = actReq?.options?.some((item) => item?.pop_up_text !== null);
    /* c8 ignore next 6 */
    if (
      actionRequest?.type === "multi-select" ||
      (actionRequest?.type === "select" && check)
    ) {
      return true;
    }
  }, [actionRequest]);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "Backspace" &&
        !isCommentModalOpen &&
        !isOtherModalOpen &&
        !stopBack &&
        actionRequest?.type !== "grid_numeric" &&
        actionRequest?.type !== "text_list" &&
        checkIsSpecify() == undefined
      )
        undoFunction();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    chatController,
    isCommentModalOpen,
    stopBack,
    actionRequest,
    undoFunction,
    checkIsSpecify,
    isOtherModalOpen,
  ]);
  const text = { text: "Send" };

  return (
    <Box sx={container}>
      {onClick && !hideSendBtn && (
        <Box>
          <ActionButton
            onClick={onClick}
            testId="landscape-send-button"
            currentVal={text}
            disabled={disableSendBtn}
          />
        </Box>
      )}
    </Box>
  );
};

export default SendUndoButton;
