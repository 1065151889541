/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Modal, TextField } from "@mui/material";
import React from "react";
import {
  InputBox,
  ModalButton,
  ModalCloseBtn,
  ModalContent,
  ModalOverLay,
  ModalText,
  TextInput,
} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { useThemeContext } from "../../../globalContext/global-context";
import Hints from "../Hints";
import { OtherModalTypes } from "../../../util/types";

const OtherModal: React.FC<OtherModalTypes> = ({
  actReq,
  setOtherOptionString,
  isOtherModalOpen,
  isLandScapeMode,
  setIsOtherModalOpen,
  setActionReqForSelectMulti,
  chatController,
}) => {
  // using global theme
  const { globalObject } = useThemeContext();
  const chatCtl = chatController;
  const globalTheme = globalObject?.themeData?.theme;
  const [interState, setInterState] = React.useState("");
  const handleCloseModal = () => {
    /* c8 ignore start */
    if (actReq?.type == "multi-select") {
      const multiselectDefaultOtherText = actReq?.options?.filter(
        (item) => item?.pop_up_text !== null
      )[0];
      if (setActionReqForSelectMulti) {
        setActionReqForSelectMulti((prevOptions: any) =>
          prevOptions?.map((item: any) =>
            item.pop_up_text !== null
              ? {
                  ...item,
                  text:
                    interState == ""
                      ? multiselectDefaultOtherText?.text
                      : interState,
                }
              : item
          )
        );
      }
      /* c8 ignore end */
    } else if (
      actReq?.type == "grid_select" ||
      actReq?.type == "grid_multi_select"
    ) {
      setOtherOptionString((prev) => ({
        ...prev,
        text: interState,
      }));
    } else {
      const res = {
        type: "select",
        value: interState || "",
      };

      chatCtl.setActionResponse(actReq, res);
    }
    setIsOtherModalOpen(false);
  };
  return (
    <Modal
      data-testid="other-modal-box"
      open={isOtherModalOpen}
      onClose={handleCloseModal}
    >
      <Box sx={ModalOverLay}>
        <Box sx={ModalContent(isLandScapeMode)}>
          <Button
            data-testid="other-close-btn"
            sx={ModalCloseBtn(globalTheme)}
            onClick={() => setIsOtherModalOpen(false)}
          >
            <CloseIcon />
          </Button>

          <Hints text={actReq?.content} sx={ModalText} />

          <Box sx={InputBox}>
            <TextField
              data-testid="other-input-box"
              onChange={(e) => setInterState(e.target.value)}
              placeholder="Please enter"
              variant="outlined"
              fullWidth
              sx={{
                ...TextInput,
                width: "90%",
                textAlign: "center",
              }}
            />
          </Box>
          <Button
            data-testid="other-submit-btn"
            sx={{
              ...ModalButton,
              backgroundColor:
                interState?.length == 0 ? "grey" : globalTheme?.main_color,
            }}
            disabled={interState?.length == 0}
            onClick={handleCloseModal}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OtherModal;
