import React, { FC } from "react";
import StarGrid from "./component/starGrid/index";
import { Box } from "@mui/material";
import { useThemeContext } from "../../../../globalContext/global-context";
import { Hints } from "../../../ui";
import { gridMainContainer, gridMainBox } from "./style";
import { RatingGridParentProps } from "src/util/types";
import NumericSelectMultiSelectSlider from "../Numeric-Select-MultiSelct-Slider";

const RatingGridParent: FC<RatingGridParentProps> = (props) => {
  const { actionRequest, chatController, isLandScapeMode } = props;
  const isGrid_numeric = actionRequest?.type == "grid_numeric";
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;

  if (actionRequest?.style == "star") {
    return (
      <Box sx={gridMainContainer}>
        <Box
          sx={gridMainBox(globalTheme, isGrid_numeric, actionRequest?.columns)}
        >
          <Box sx={{ width: "100%" }}>
            <Hints text={actionRequest?.content} />
            {actionRequest?.error_message && (
              <Hints
                text={actionRequest?.error_message}
                sx={{ color: globalTheme?.error_color }}
              />
            )}
            <StarGrid
              isLandScapeMode={isLandScapeMode}
              actionRequest={actionRequest}
              chatController={chatController}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return <NumericSelectMultiSelectSlider {...props} />;
  }
};

export default RatingGridParent;
