import { FC, useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ImageQuestionProps } from "../../../util/types";
import { ActionButton } from "../../ui";
import { actionBtnWrapper } from "../Rating/styles";
import { imageContainer, imageStyle } from "./styles";
import { useThemeContext } from "../../../globalContext/global-context";
import { loadingContainer } from "../Rating/components/ButtonRating/styles";

const ImageQuestion: FC<ImageQuestionProps> = ({
  chatController,
  handleImageClick,
  surveyQuestion,
  actionRequest,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject.themeData.theme;
  const question = surveyQuestion?.question;
  const [loaded, setLoaded] = useState(false);

  const handleSubmit = useCallback(() => {
    const res = { value: "ok", type: "image" };
    chatController.setActionResponse(actionRequest, res);
  }, [chatController, actionRequest]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  const text = { text: "Next" };
  return (
    <Box data-testid="image-input" sx={imageContainer(globalTheme)}>
      {!loaded && (
        <Box sx={loadingContainer}>
          <CircularProgress sx={{ color: globalTheme?.main_color }} />
        </Box>
      )}
      <Box
        component="img"
        src={question?.image}
        alt={question?.alt_text}
        onClick={() => handleImageClick(question?.image, !!question?.clickable)}
        sx={imageStyle}
        onLoad={() => setLoaded(true)}
        data-testid="image-question"
      />

      <Box
        sx={{
          ...actionBtnWrapper,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ActionButton
          onClick={handleSubmit}
          testId="submit-image"
          currentVal={text}
        />
      </Box>
    </Box>
  );
};

export default ImageQuestion;
