import React, { useEffect, useState } from "react";
import {
  MarkValueType,
  MultiSliderValueType,
  row_options_element,
  ThisOrThatSelectBtnProps,
  ThisOrThatTypes,
} from "../../../../util/types";
import { Box } from "@mui/system";
import { Hints, SendUndoButton } from "../../../ui";
import { actionBtnThisOrThat, questionWrapper } from "./style";
import { useThemeContext } from "../../../../globalContext/global-context";
import ThisOrThatColumn from "./ThisOrThatColumn";
import ThisOrThatButtonSlider from "./ThisOrThatButtonSlider";
import { interval } from "../components/GridCommonFunctions";

const ThisOrThat: React.FC<ThisOrThatTypes> = ({
  actionRequest,
  chatController,
  isCommentModalOpen,
}) => {
  const [selectedBtn, setSelectedBtn] = useState<ThisOrThatSelectBtnProps[]>(
    []
  );
  const [updateMarkValue, setUpdateMarkValue] = React.useState<MarkValueType[]>(
    []
  );
  const defaultSliderSelectedVal = actionRequest?.row_options?.map(() => {
    return {
      sliderRatingValue: 50,
    };
  });
  const [sliderValue, setSliderValue] = useState<MultiSliderValueType[]>(
    defaultSliderSelectedVal
  );
  const [isAllSliderValueChange, setIsAllSliderValueChange] = useState<
    MultiSliderValueType[]
  >([]);

  const { globalObject } = useThemeContext();

  const globalTheme = globalObject?.themeData?.theme;
  const handleSubmit = () => {
    const isStyleCheck = actionRequest?.style?.toLowerCase();

    const sliderOptions = Object?.values(sliderValue)?.map((sliderObj) => {
      const matchedMark = updateMarkValue?.find(
        (mark) => mark.value === sliderObj.sliderRatingValue
      );
      /* c8 ignore next 4 */
      return {
        value: matchedMark?.resValue,
      };
    });
    const getValueOption =
      isStyleCheck === "slider" ? sliderOptions : selectedBtn;
    const option = getValueOption?.map((item) => {
      return { value: item?.value };
    });
    const res = {
      type: actionRequest?.type,
      value: option?.map((item) => item.value).toString(),
      options: option,
    };
    chatController.setActionResponse(actionRequest, res);
  };
  const handleClick = (
    option: { value: string; text: string },
    id: number,
    column: string
  ) => {
    const checkSameValueIsExist = selectedBtn?.some(
      (item: ThisOrThatSelectBtnProps) => item?.id === id
    );
    if (!checkSameValueIsExist) {
      setSelectedBtn((prev) => {
        return [
          ...prev,
          {
            value: option?.value,
            text: option?.text,
            id: id,
            column: column,
          },
        ];
      });
    } else {
      const replaceSameSelectVal = selectedBtn?.filter(
        (item) => item.id !== id && item.value !== option?.value
      );
      const newData = [
        ...replaceSameSelectVal,
        {
          value: option?.text,
          id: id,
        },
      ];
      setSelectedBtn(newData);
    }
  };

  useEffect(() => {
    if (
      actionRequest?.type === "grid_this_or_that_rating" &&
      actionRequest?.style == "slider"
    ) {
      const updatedOptions = actionRequest?.row_options?.flatMap(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => {
          return item.map((val: row_options_element, ind: number) => {
            return {
              text: val.text,
              display_text: val.display_text,
              resValue: (ind + 1).toString(),
              value:
                ind *
                interval({
                  options: item,
                  type: actionRequest?.type,
                }),
            };
          });
        }
      );
      setUpdateMarkValue(updatedOptions);
    }
  }, [actionRequest?.type, actionRequest?.row_options, actionRequest?.style]);

  const checkIsAllSelected = () => {
    if (actionRequest?.style === "slider") {
      /* c8 ignore next 2 */
      const expectedLength = actionRequest?.row_options?.length || 0;
      const hasAllValues = Array.from(
        { length: expectedLength }, 
        (_, i) => isAllSliderValueChange[i]
      ).every(item => item?.sliderRatingValue !== undefined);

      return !hasAllValues;
    } else {
      return selectedBtn?.length !== actionRequest?.row_options?.length;
    }
  };

  return (
    <Box data-testid="This-Or-That-button-Slider">
      <Box sx={questionWrapper(globalTheme.main_color)}>
        <Hints text={actionRequest?.content} />
        {actionRequest?.error_message && (
          <Hints
            text={actionRequest?.error_message}
            sx={{ color: globalTheme?.error_color }}
          />
        )}
        {/* render this or that column component */}
        {actionRequest?.type === "grid_this_or_that" && (
          <ThisOrThatColumn
            RowsOption={actionRequest?.row_options}
            handleClick={handleClick}
            selectedBtns={selectedBtn}
          />
        )}
        {/* render this or that Slider / Button  component */}
        {actionRequest?.type === "grid_this_or_that_rating" && (
          <ThisOrThatButtonSlider
            actionRequest={actionRequest}
            handleClick={handleClick}
            selectedBtns={selectedBtn}
            updateMarkValue={updateMarkValue}
            setUpdateMarkValue={setUpdateMarkValue}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            setIsAllSliderValueChange={setIsAllSliderValueChange}
          />
        )}
      </Box>
      <Box sx={{ ...actionBtnThisOrThat }}>
        <SendUndoButton
          isCommentModalOpen={isCommentModalOpen}
          actionRequest={actionRequest}
          chatController={chatController}
          globalTheme={globalTheme}
          onClick={handleSubmit}
          disableSendBtn={checkIsAllSelected()}
        />
      </Box>
    </Box>
  );
};

export default ThisOrThat;
