import React, { FC, useEffect, useRef, useState } from "react";
import StarComponent from "../../../../../ui/starComponent/index";
import { Box, Typography } from "@mui/material";
import { useThemeContext } from "../../../../../../globalContext/global-context";
import CommonImageComp from "../../../../../ui/CommonImageComp/index";

import SendUndoButton from "../../../../../ui/SendUndoButton";
import { actionBtnWrapper } from "../../style";
import {
  GridSelectAction,
  StarGridComponentProps,
  newRowWithImage,
} from "src/util/types";
import {
  columnContainer,
  columnInnerContainer,
  CommonImageStyle,
  LeftSideChildStyle,
  LeftSideContainer,
  RightSideContainer,
  rowratingPointText,
  starComponentContainer,
  textContainer,
} from "./style";
import Markdown from "markdown-to-jsx";
import { ExternalLink } from "../../../../../ui/Hints";

const StarGrid: FC<StarGridComponentProps> = ({
  actionRequest,
  isLandScapeMode,
  chatController,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  const refs = useRef<HTMLDivElement[]>([]);
  const StarsRefs = useRef<HTMLDivElement[]>([]);
  const [stars, setStars] = useState<number[]>([]);
  const [starRating, setStarRating] = useState<Record<number, number>>({});
  const chatCtl = chatController;

  const handleResize = () => {
    if (refs.current) {
      const maxHeight = refs.current.reduce((max, ref, idx) => {
        /* c8 ignore next 2 */
        const buttonClientHeight = ref?.clientHeight ?? 0;
        const questionClientHeight = StarsRefs.current[idx]?.clientHeight ?? 0;
        const result = Math.max(buttonClientHeight, questionClientHeight) - 10;

        return Math.max(max, result);
      }, 0);
      const heights = new Array(refs.current.length).fill(maxHeight);
      setStars(heights);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [actionRequest.rows]);

  /**
   * *sets star rating  for each row
   */
  const handleStarChange = (rowIndex: number, rating: number) => {
    setStarRating((prevRating) => ({ ...prevRating, [rowIndex]: rating }));
  };

  const handleSubmit = () => {
    const Values = Object.values(starRating).map((item) => item);
    const res = {
      type: actionRequest?.type,
      value: Values.join(","),
      options: Object.values(starRating).map((item) => ({ value: item })),
    };

    chatCtl.setActionResponse(actionRequest, res);
  };

  /*
   * disbales send btn until all rows have a value
   */
  const isSubmitDisabled = (actionRequest: GridSelectAction) => {
    return Object.values(starRating).length !== actionRequest?.rows?.length;
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* left side section */}
      <Box sx={LeftSideContainer}>
        {/* container for rows  */}
        <Box sx={LeftSideChildStyle}>
          {actionRequest?.rows?.map((row: newRowWithImage, index) => {
            const isImageExist = row?.image !== null;
            return (
              <Box
                key={`grid-btn-${index + 1}`}
                sx={textContainer(isImageExist, stars[index])}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    ref={(el) => (refs.current[index] = el as HTMLDivElement)}
                    title={row?.text}
                    sx={rowratingPointText(globalTheme, isImageExist)}
                  >
                    <Markdown
                      options={{
                        overrides: {
                          img: {
                            props: {
                              className: "custom-image", // Apply custom Material-UI styles
                            },
                          },
                          a: {
                            component: ExternalLink,
                          },
                        },
                        disableParsingRawHTML: true,
                      }}
                    >
                      {row?.text}
                    </Markdown>
                  </Typography>
                </Box>
                {row?.image && (
                  <Box sx={{ width: "40%" }}>
                    <CommonImageComp
                      currentVal={{
                        image: row?.image,
                      }}
                      sx={CommonImageStyle(stars[index])}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* Right side section */}
      <Box sx={RightSideContainer}>
        <Box sx={columnContainer(globalTheme)}>
          {actionRequest.options?.map((eachOptions, index) => (
            <Box sx={columnInnerContainer} key={`top-lable-${index + 1}`}>
              <Typography
                variant="h6"
                sx={{ fontSize: { md: "20px", xs: "12px" }, fontWeight: 600 }}
              >
                {eachOptions.text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={starComponentContainer}>
          {actionRequest.rows?.map((eachRow, index) => (
            <StarComponent
              key={`Star-${index + 1}`}
              StarsRef={StarsRefs}
              starContainerHeight={stars[index]}
              maxStars={actionRequest.options?.length}
              isLandScapeMode={isLandScapeMode}
              onStarChange={(rating: number) => handleStarChange(index, rating)}
            />
          ))}
        </Box>
      </Box>
      <Box sx={actionBtnWrapper}>
        <SendUndoButton
          isCommentModalOpen={false}
          actionRequest={actionRequest}
          chatController={chatController}
          globalTheme={globalTheme}
          onClick={handleSubmit}
          disableSendBtn={isSubmitDisabled(actionRequest)}
        />
      </Box>
    </Box>
  );
};

export default StarGrid;
