import React, { FC } from "react";
import { StarRatingProps } from "../../../../../util/types";
import RatingCommonTemplate from "../CommonTemplate";
import StarComponent from "../../../../ui/starComponent";
import { Box } from "@mui/material";
const StarRating: FC<StarRatingProps> = (props): React.ReactElement => {
  const { actionRequest, handleStarRating, isLandScapeMode } = props;

  return (
    <RatingCommonTemplate
      {...props}
      dataTestId="star-rating"
      children={
        <Box sx={{ marginTop: 2 }}>
          <StarComponent
            maxStars={actionRequest?.options?.length}
            onStarChange={handleStarRating}
            isLandScapeMode={isLandScapeMode}
          />
        </Box>
      }
    />
  );
};

export default StarRating;
