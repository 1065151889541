import { SxProps } from "@mui/material";
import { ThemeObjectInterFace } from "../../../util/types";

// other modal styles
export const ModalOverLay: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

export const InputBox: SxProps = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export const TextInput: SxProps = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
  },
  "& .MuiInputBase-input": {
    padding: "14px",
  },
};

export const ModalButton: SxProps = {
  color: "white",
  padding: "5px 80px",
  fontSize: "1.2rem",
};

export const ModalContent = (isLandScapeMode: boolean): SxProps => ({
  background: "white",
  minWidth: isLandScapeMode ? " 350px" : { xs: "65%", md: "22%" },
  maxWidth: isLandScapeMode ? " 450px" : { xs: "65%", md: "80%" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "25px 14px",
  rowGap: "23px",
  borderRadius: "25px",
  position: "relative",
});

export const ModalText: SxProps = {
  fontWeight: "600",
  color: "black",
  fontSize: "1rem",
  margin: 0,
};

export const ModalCloseBtn = (globalTheme: ThemeObjectInterFace): SxProps => ({
  background: globalTheme.main_color,
  position: "absolute",
  right: "-10px",
  top: "-8px",
  padding: 0,
  minWidth: "30px",
  minHeight: "30px",
  borderRadius: "50%",
  color: "white",
  cursor: "pointer",
});
