import React, { useEffect, useState } from "react";
import "./GridSelectAndMultiSelect.css";
import { GridSelectAndMultiSelectProps } from "./type";
import { actionBtnWrapper } from "../../Rating/styles";
import { Box, Checkbox, Radio } from "@mui/material";
import { Hints, SendUndoButton } from "../../../ui";
import { useThemeContext } from "../../../../globalContext/global-context";
import { ActionResponse } from "chat-ui-react";
import { gridContainer } from "./styles";
const GridSelectComponent: React.FC<GridSelectAndMultiSelectProps> = ({
  actionRequest,
  chatController,
  isCommentModalOpen,
  multiSelect,
  setIsOtherModalOpen,
  isOtherModalOpen,
  setOtherOptionString,
  otherOptionString,
}) => {
  const [singleSelections, setSingleSelections] = useState<
    Record<string, number | string | undefined>
  >({});
  const [multiSelections, setMultiSelections] = useState<
    Record<string, (number | string | undefined)[]>
  >({});

  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;

  const handleSingleSelect = (
    rowValue: string,
    option: { text: string; value: number; pop_up_text?: string | null }
  ) => {
    const commonSingleSeleect = () => {
      setSingleSelections((prev) => ({
        ...prev,
        [rowValue]: option.value,
      }));
    };
    /* c8 ignore start */
    if (
      option?.pop_up_text !== null &&
      option?.pop_up_text !== undefined &&
      setIsOtherModalOpen &&
      setOtherOptionString
    ) {
      setIsOtherModalOpen(true);
      setOtherOptionString({
        key: rowValue,
        text: option?.text,
        value: option.value, // Add this to track which option was selected
      });
      /* c8 ignore end */
      return commonSingleSeleect();
    } else {
      return commonSingleSeleect();
    }
  };
  /* c8 ignore start */
  useEffect(() => {
    if (otherOptionString?.key && otherOptionString?.text) {
      const keyString = otherOptionString.key;
      const commonPopupValidationCheck =
        otherOptionString.text !== "" &&
        !actionRequest?.options?.some(
          (item) =>
            item?.pop_up_text !== null && item?.text === otherOptionString?.text
        );
      if (actionRequest?.style === "radio") {
        setSingleSelections((prev) => ({
          ...prev,
          [keyString]: commonPopupValidationCheck
            ? otherOptionString.text
            : otherOptionString.value,
        }));
      } else {
        setMultiSelections((prev) => {
          if (!keyString || otherOptionString?.value === undefined) {
            return prev; // Prevent unintended state modifications
          }

          const currentSelections = prev[keyString] || [];
          if (currentSelections.includes(otherOptionString?.value as number)) {
            // Remove the option if already selected
            return {
              ...prev,
              [keyString]: currentSelections.filter(
                (value) => value !== otherOptionString?.value
              ),
            };
          } else {
            // Add the option to the selections
            return {
              ...prev,
              [keyString]: [
                ...currentSelections,
                otherOptionString?.text, // Ensure this is the correct value to be added
              ],
            };
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherModalOpen]);
  const handleMultiSelect = (
    rowValue: string,
    option: { text: string; value: number; pop_up_text?: string | null }
  ) => {
    const isExist = multiSelections[rowValue]?.some(
      (item) => typeof item === "string"
    );
    if (
      !isExist &&
      option?.pop_up_text !== null &&
      option?.pop_up_text !== undefined &&
      setIsOtherModalOpen &&
      setOtherOptionString
    ) {
      setIsOtherModalOpen(true);
      setOtherOptionString({
        key: rowValue,
        text: option?.text,
        value: option?.value,
      });
      setMultiSelections((prev) => {
        const currentSelections = prev[rowValue] || [];

        return {
          ...prev,
          [rowValue]: [...currentSelections, option?.value],
        };
      });
    } else {
      setMultiSelections((prev) => {
        const currentSelections = prev[rowValue] || [];
        const checkTheOtherString = currentSelections.find(
          (item) => typeof item === "string" && option?.pop_up_text !== null
        );
        const isExist = () => {
          if (checkTheOtherString) {
            return checkTheOtherString;
          } else {
            return option?.value;
          }
        };
        if (currentSelections.includes(isExist())) {
          return {
            ...prev,
            [rowValue]: currentSelections.filter(
              (value) => value !== isExist()
            ),
          };
        } else {
          // Add the option to the selections
          return {
            ...prev,
            [rowValue]: [...currentSelections, isExist()],
          };
        }
      });
    }
  };
  /* c8 ignore end */
  const handleSubmit = () => {
    if (multiSelect) {
      // Check if all rows have selections for multi-select
      const allRowsSelected = actionRequest.rows.every(
        (row) => multiSelections[row.value]?.length > 0
      );

      if (allRowsSelected) {
        const options = actionRequest.rows.map((row) => ({
          value: multiSelections[row.value].join(","),
        }));

        const res = {
          type: actionRequest?.type,
          value: options.map((item) => item.value).join("|"),
          options,
        };
        chatController.setActionResponse(actionRequest, res as ActionResponse);
      }
    } else {
      // Check if all rows have selections for single-select
      const allRowsSelected = actionRequest.rows.every(
        (row) => singleSelections[row.value] !== undefined
      );

      if (allRowsSelected) {
        const options = actionRequest.rows.map((row) => ({
          value: singleSelections[row.value]?.toString(),
        }));

        const res = {
          type: actionRequest?.type,
          value: options.map((item) => item.value).toString(),
          options,
        };
        chatController.setActionResponse(actionRequest, res as ActionResponse);
      }
    }
    if (setOtherOptionString) setOtherOptionString({ text: "", value: 0 });
  };

  const requiredItemSelected = () => {
    let isAllSelected;
    if (multiSelect) {
      isAllSelected = !actionRequest.rows.every(
        (row) => multiSelections[row.value]?.length > 0
      );
      return isAllSelected;
    } else {
      isAllSelected =
        Object.keys(singleSelections).length !== actionRequest?.rows.length;
      return isAllSelected;
    }
  };
  return (
    <Box className="grid-parent-container" data-testid="grid-select-component">
      <Box sx={gridContainer(globalTheme)}>
        <Hints
          text={actionRequest.content}
          sx={{
            color: globalTheme?.main_color,
            fontSize: "18px",
            marginY: "20px",
            fontWeight: 500,
            padding: "0 1rem",
          }}
        />
        {actionRequest?.error_message && (
          <Hints
            text={actionRequest?.error_message}
            sx={{ color: globalTheme?.error_color }}
          />
        )}
        <table className="grid-table">
          <thead>
            <tr>
              <th></th>
              {actionRequest.options.map((option) => (
                <th
                  style={{ color: globalTheme?.main_color }}
                  key={option.value}
                >
                  {option.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {actionRequest.rows.map((row, rIndex) => (
              <tr key={row.value}>
                <td>
                  {row.image ? (
                    <img alt="" src={row.image} width={50} height={65} />
                  ) : (
                    row.text
                  )}
                </td>
                {actionRequest.options.map((option, oIndex) => (
                  <td key={`${row.value}-${option.value}`}>
                    <Box
                      className={
                        multiSelect ? "checkbox-wrapper" : "radio-wrapper"
                      }
                    >
                      {multiSelect ? (
                        <Checkbox
                          name={row.value}
                          value={option.value}
                          data-testid={`grid-select-${rIndex}-${oIndex}`}
                          checked={
                            (option?.pop_up_text &&
                              multiSelections[row.value]?.some(
                                (item) => typeof item === "string"
                              )) ||
                            multiSelections[row.value]?.includes(
                              option.value
                            ) ||
                            false
                          }
                          disabled={option.disabled}
                          onChange={() => handleMultiSelect(row.value, option)}
                          sx={{
                            position: "relative",
                            appearance: "none",
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            cursor: "pointer",
                            backgroundColor: "white",
                            color: globalTheme?.main_color,

                            "&.Mui-checked": {
                              color: globalTheme?.main_color,
                            },
                          }}
                          aria-label={`${row.text} - ${option.text}`}
                        />
                      ) : (
                        <Radio
                          name={row.value}
                          value={option.value}
                          data-testid={`grid-select-${rIndex}-${oIndex}`}
                          checked={
                            (option?.pop_up_text &&
                              typeof singleSelections[row.value] ===
                                "string") ||
                            singleSelections[row.value] === option.value
                          }
                          disabled={option.disabled}
                          onChange={() => handleSingleSelect(row.value, option)}
                          sx={{
                            position: "relative",
                            appearance: "none",
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            cursor: "pointer",
                            backgroundColor: "white",
                            color: globalTheme?.main_color,

                            "&.Mui-checked": {
                              color: globalTheme?.main_color,
                            },
                          }}
                          aria-label={`${row.text} - ${option.text}`}
                        />
                      )}
                    </Box>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      <Box sx={actionBtnWrapper}>
        <SendUndoButton
          disableSendBtn={requiredItemSelected()}
          actionRequest={actionRequest}
          onClick={handleSubmit}
          globalTheme={globalTheme}
          chatController={chatController}
          isCommentModalOpen={isCommentModalOpen}
          isOtherModalOpen={isOtherModalOpen}
        />
      </Box>
    </Box>
  );
};

export default GridSelectComponent;
