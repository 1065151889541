/* eslint-disable react-refresh/only-export-components */
import React from "react";
import {
  GridNumericType,
  GridSelectAction,
  interValFunArgumentType,
  SelectedtValue,
} from "../../../../../util/types";

export interface PassProps {
  isGrid_numeric: boolean;
  actionRequest: GridSelectAction;
  selectedValue: SelectedtValue[];
  setGridNumericValues: React.Dispatch<React.SetStateAction<GridNumericType[]>>;
  autosum_columns?: boolean; // Include autosum_columns as a prop if needed
}

export const NumerGridTotalValue = (props: PassProps) => {
  const {
    actionRequest,
    selectedValue,
    setGridNumericValues,
    autosum_columns,
  } = props;

  // Summing the values based on colIndex or key
  const summedArray = Object.values(
    selectedValue?.reduce(
      (
        acc: {
          [key: string]: { key?: number; colIndex?: number; value: number };
        },
        { key, value, colIndex }
      ) => {
        if (!actionRequest?.columns || autosum_columns) {
          if (colIndex !== undefined) {
            if (!acc[colIndex]) {
              acc[colIndex] = { colIndex, value: 0 };
            }
            acc[colIndex].value += value;
          }
        } else {
          /* c8 ignore next 5 */
          if (!acc[key]) {
            acc[key] = { key, value: 0 };
          }
          /* c8 ignore next 1 */
          acc[key].value += value;
        }
        return acc;
      },
      {}
    )
  );
  const gridInitialArray = () => {
    if (!actionRequest?.columns) {
      /* c8 ignore next 1*/
      return [""];
    } else if (autosum_columns) {
      return actionRequest?.columns;
    } else {
      return actionRequest?.rows;
    }
  };

  const initialObjectArray = gridInitialArray()?.map((_, index) => {
    return !actionRequest?.columns || autosum_columns
      ? { colIndex: index, value: 0 }
      : { key: index, value: 0 };
  });
  // Count occurrences of each `key` in `summedArray`
  const keyCount = selectedValue
    ?.filter(({ emptyString }) => emptyString !== true)
    ?.reduce(
      (
        acc: { [key: string]: number; [colIndex: number]: number },
        { key, colIndex }
      ) => {
        if (!actionRequest?.columns || autosum_columns) {
          if (colIndex !== undefined) {
            acc[colIndex] = (acc[colIndex] || 0) + 1;
          }
        } else {
          /* c8 ignore next 1*/
          acc[key] = (acc[key] || 0) + 1;
        }
        return acc;
      },
      {}
    );

  // Replace `value` where `key` matches the index from `summedArray`
  const resultArray = initialObjectArray?.map((item) => {
    const isAutosum = autosum_columns || !actionRequest?.columns;
    const matched = summedArray.find((sumItem) =>
      isAutosum
        ? sumItem?.colIndex === item?.colIndex
        : sumItem?.key === item?.key
    );

    // Create a new item object and ensure `inputValidColor` is part of it
    const newItem = {
      ...item,
      value: matched?.value ?? item.value,
      inputValidColor: false, // Initialize with default value
    };

    const keyOrColIndex = isAutosum ? matched?.colIndex : matched?.key;
    const validLength = isAutosum
      ? actionRequest?.rows?.length
      : actionRequest?.columns?.length;

    // Set `inputValidColor` if conditions match
    if (matched && keyCount[keyOrColIndex as number] === validLength) {
      newItem.inputValidColor = true;
    }

    return newItem;
  });

  if (resultArray) {
    setGridNumericValues(resultArray);
  }
};

export const submitDisabledFun = (
  actionRequest: GridSelectAction,
  multiSelectLength: number,
  isSliderRespondedLength: number,
  gridNumericValues: GridNumericType[],
  selectedValueLength: number
) => {
  const defautVal =
    multiSelectLength >= actionRequest?.rows.length ||
    isSliderRespondedLength === actionRequest?.rows.length;
  if (actionRequest?.type == "grid_numeric") {
    return gridNumericValues?.every(
      ({ inputValidColor }) => inputValidColor === true
    );
  } else if (actionRequest?.type == "grid_select") {
    return selectedValueLength === actionRequest?.rows.length;
  } else {
    return defautVal;
  }
};

export const autsumColumnsCheck = (
  isGrid_numeric: boolean,
  autosum_columns: boolean | undefined,
  columns: boolean
) => {
  if (isGrid_numeric) {
    if (columns || autosum_columns) {
      return true;
    }
  }
};

// calculation for render slid range  point wise
export const interval = (interValArgument: interValFunArgumentType) => {
  const { options, type } = interValArgument;
  if (
    (options && type == "grid_rating") ||
    type == "grid_this_or_that_rating"
  ) {
    return 100 / (options.length - 1);
  } else {
    return 0;
  }
};
