import { Box } from "@mui/material";
import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  starRatingInnerContainer,
  starIconeWraper,
  starIconStyle,
} from "./style";
import { useThemeContext } from "../../../globalContext/global-context";
import { StarComponentProps } from "../../../util/types";

const StarComponent: React.FC<StarComponentProps> = ({
  maxStars,
  StarsRef,
  starContainerHeight,
  onStarChange,
  isLandScapeMode,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;

  const [selectedStar, setSelectedStar] = useState(0);

  const handleStarClick = (starValue: number) => {
    setSelectedStar(starValue);
    /**
     * * sends the values of each row
     */
    onStarChange(starValue);
  };

  return (
    <Box sx={starRatingInnerContainer(isLandScapeMode)}>
      {[...Array(maxStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <Box
            key={starValue}
            onClick={() => handleStarClick(starValue)}
            sx={starIconeWraper}
            /**
             * * dynamic height for star container
             * * starContainerHeight
             */
            height={starContainerHeight}
            ref={
              /* c8 ignore next 9 */
              StarsRef
                ? (el: HTMLDivElement | null) => {
                    if (StarsRef.current && el) {
                      StarsRef.current[index] = el;
                    }
                  }
                : undefined
            }
          >
            {starValue <= selectedStar ? (
              <StarIcon
                data-testid={`star-filled-${starValue}`}
                sx={starIconStyle({ maxStars, globalTheme })}
              />
            ) : (
              <StarBorderIcon
                data-testid={`star-unfilled-${starValue}`}
                sx={starIconStyle({ maxStars, globalTheme })}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default StarComponent;
