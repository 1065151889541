import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../../util/types";

export const valueContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
};

/* c8 ignore next */
export const valueContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: "center",
  fontWeight: 300,
  width: 0,
});

/* c8 ignore start */
export const sliderStyle = (
  globalTheme: ThemeObjectInterFace,
  isSlider: boolean,
  source?: string
): SxProps => ({
  color: globalTheme?.main_color,
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 30,
    width: 30,
    marginLeft: "5px",
    display: isSlider ? "none" : "block",
  },

  "& .MuiSlider-track": {
    border: "none",
    opacity: isSlider ? 0.5 : null,
    color: isSlider ? "#bfbfbf" : "",
  },
  "& .MuiSlider-markActive": isSlider
    ? {
        color: "white",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 20,
        borderColor: globalTheme?.main_color,
      }
    : {},

  "& .MuiSlider-rail": {
    ...(source == "this-or-that"
      ? { backgroundColor: globalTheme?.main_color, opacity: 1 }
      : { opacity: 0.5, backgroundColor: "#bfbfbf" }),
  },
  "& .MuiSlider-mark": {
    backgroundColor: "white",
    height: "10px",
    width: "10px",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,

    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: source == "this-or-that" ? "white" : "currentColor",
    },
  },
});
/* c8 ignore end */
