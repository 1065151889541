import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../../util/types";

/* c8 ignore next 7 */
export const textContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: "center",
  fontWeight: 300,
  marginBottom: 2,
});

export const loadingContainer: SxProps = {
  padding: "2rem 0",
  display: "flex",
  justifyContent: "center",
};

export const valueContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

/* c8 ignore next 8 */
export const valueContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: "center",
  fontWeight: 300,
  width: 0,
});

export const selectedValueContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "-2px",
};
